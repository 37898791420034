
/* ../source/dom/dom.css */

/* things we always want */
body {
	font-family: 'Helvetica Neue', 'Nimbus Sans L', Arial, sans-serif;
}

/* allow hw-accelerated scrolling on platforms that support it */
body.webkitOverflowScrolling {
	-webkit-overflow-scrolling: touch;
}

/* for apps */
.enyo-document-fit {
	margin: 0;
	height: 100%;
	/* note: giving html overflow: auto is odd and was only ever done to avoid duplication
		however, android 4.04 sometimes does not hide nodes when their display is set to none
		if document is overflow auto.
	*/
	position: relative;
}

.enyo-body-fit {
	margin: 0;
	height: 100%;
	/* helps prevent ios page scroll */
	overflow: auto;
	position: relative;
}

/* reset */

button {
	font-size: inherit;
	font-family: inherit;
}

/* user selection */

.enyo-unselectable {
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	user-select: none;
}

.enyo-unselectable::selection, .enyo-unselectable ::selection {
	color: transparent;
}

.enyo-selectable {
	cursor: auto;
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
}

.enyo-selectable::selection, .enyo-selectable ::selection {
	background: #3297FD;
	color: #FFF;
}

/* layout */

body .enyo-fit {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.enyo-clip {
	overflow: hidden;
}

.enyo-border-box {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* compositing */

.enyo-composite {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
}


/* ../source/touch/Thumb.css */

.enyo-thumb {
	position: absolute;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px;
	background: #333;
	border: 1px solid #666;
	opacity: 0.75;
	z-index: 1;
}

.enyo-vthumb {
	top: 0;
	right: 2px;
	width: 4px;
}

.enyo-hthumb {
	left: 0;
	bottom: 2px;
	height: 4px;
}


/* ../source/touch/Scroller.css */

.enyo-scroller {
	position: relative;
}

.enyo-fit.enyo-scroller {
	position: absolute;
}

.enyo-touch-scroller {
	overflow: hidden;
}

.enyo-touch-strategy-container {
	overflow: hidden;
}

.enyo-scrollee-fit {
	height: 100%;
}

/* ../source/ui/ui.css */

.enyo-inline, .enyo-tool-decorator {
	display: inline-block;
}

.enyo-children-inline > *, .enyo-tool-decorator > * {
	display: inline-block;
}

.enyo-children-middle > *, .enyo-tool-decorator > * {
	vertical-align: middle;
}

.enyo-positioned {
	position: relative;
}

.enyo-fill {
	position: relative;
	width: 100%;
	height: 100%;
}

.enyo-popup {
	position: absolute;
	z-index: 10;
}
